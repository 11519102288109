<template>
  <footer class="uproster-footer" v-if="!isLoggedIn">
    <div class="container">
      <div class="row align-items-center">
        <div class="col">
          <p>
            © {{ $filters.dateFormat("", "YYYY") }} Uproster owned by Leemo. All
            rights reserved
          </p>
        </div>
        <div class="col">
          <div class="footer-links">
            <ul class="d-flex align-items-center justify-content-end">
              <li><a href="#">Terms &amp; Conditions</a></li>
              <li><a href="#">Privacy Policy</a></li>
              <li><a href="#">Disclaimer</a></li>
              <li class="social-link">
                <a class="facebook" href="/" target="_blank">
                  <img alt="facebook" src="@/assets/images/facebook.svg" />
                </a>
                <a class="instagram" href="/" target="_blank">
                  <img alt="Instagram" src="@/assets/images/instagram.svg" />
                </a>
                <a class="twitter" href="/" target="_blank">
                  <img alt="Twitter" src="@/assets/images/twitter.svg" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- footer html -->
  <footer
    class="site-footer d-flex align-items-center justify-content-between"
    v-else
  >
    <p class="copyrights">
      © {{ $filters.dateFormat("", "YYYY") }} Uproster owned by Leemo. All
      rights reserved
    </p>
    <div class="footer-nav">
      <ul class="d-flex">
        <li><a href="#">Terms & Conditions</a></li>
        <li><a href="#">Privacy Policy</a></li>
        <li><a href="#"> Disclaimer</a></li>
      </ul>
    </div>
    <div class="footer-social-nav">
      <ul class="d-flex">
        <li>
          <a href="#"><font-awesome-icon :icon="['fab', 'facebook']" /></a>
        </li>
        <li>
          <a href="#"><font-awesome-icon :icon="['fab', 'instagram']" /></a>
        </li>
        <li>
          <a href="#"><font-awesome-icon :icon="['fab', 'twitter']" /></a>
        </li>
      </ul>
    </div>
  </footer>
</template>
<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faFacebook,
  faStackOverflow,
  faGithub,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

library.add(
  faBars,
  faTwitter,
  faFacebook,
  faInstagram,
  faStackOverflow,
  faGithub
);

export default {
  name: "Footer",
  components: {
    FontAwesomeIcon,
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
  },
};
</script>
