import {
    createApp
} from 'vue';
import axios from 'axios';
import App from './App.vue';
import router from './router';
import store from './store/index';
import VueToast from 'vue-toast-notification';
// Import one of the available themes
import 'vue-toast-notification/dist/theme-default.css';
import vClickOutside from 'click-outside-vue3';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import filters from './filters';
import IdleVue from 'idle-vue';
import VueTippy from 'vue-tippy';
import 'tippy.js/dist/tippy.css'

const app = createApp(App).use(store).use(router).use(VueToast);
app.use(IdleVue, {
    store,
    idleTime: 1800000
});
app.use(vClickOutside);
app.use(VueSweetalert2);
app.config.globalProperties.$filters = filters;
app.use(VueTippy);
app.mount('#app');


axios.interceptors.response.use(undefined, function (error) {
    return new Promise(function (resolve, reject) {
        const originalRequest = error.config;
        
        if (error && error.response) {
            if (error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                store.dispatch('logout');
                store.commit('loadingStatus', false);
                return router.push({
                    name: 'Login'
                });
            }

            if (error.response.status === 403 && !originalRequest._retry) {
                originalRequest._retry = true;
                store.dispatch("getUserDetails");
            }
        }

        reject(error);
    });
});