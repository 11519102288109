<template>
  <header
    :class="
      isLoggedIn
        ? 'site-header d-flex align-items-center justify-content-between'
        : 'uproster-header'
    "
  >
    <!-- Header For Guest User -->
    <template v-if="!isLoggedIn">
      <div class="container d-flex align-items-center">
        <div class="uproster-site-logo">
          <a href="/"
            ><img alt="Uproster" src="@/assets/images/uproster.svg"
          /></a>
        </div>
        <div class="uproster-site-links">
          <ul class="d-flex align-items-center">
            <li><a href="/">Features</a></li>
            <li><a href="/">Pricing</a></li>
            <li><a href="/">About Us</a></li>
            <li><a href="/">Contact Us</a></li>
            <li>
              <router-link :to="{ name: 'Login' }">Login</router-link>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <!-- Header For Login User -->
    <template v-else>
      <div class="primary-logo">
        <ul class="d-flex align-items-center">
          <li>
            <a href="javascript:void(0)"
              ><img class="logo" src="@/assets/images/lxsiq.svg"
            /></a>
          </li>
          <li>
            <router-link :to="{ name: 'Home' }"
              ><img src="@/assets/images/uproster.svg"
            /></router-link>
          </li>
        </ul>
      </div>
      <div class="pageTitle">
        <h2 v-if="pageTitle != 'Login'">
          {{ pageTitle }}
          <template
            v-if="
              contractUsername &&
              ['ProfileRosterContracts', 'ProfileRosterBilling'].includes(
                $route.name
              )
            "
            >({{ contractUsername }})</template
          >
        </h2>
      </div>
      <div class="primary-nav d-flex align-items-center">
        <div
          v-click-outside="onClickOutsideProfileMenu"
          class="profileDropdown"
        >
          <div class="nav-dropdown">
            <div class="user-menu-wrap">
              <div
                v-if="user"
                :class="{ show: profileMenuActive }"
                class="profile-dropdown"
              >
                <button
                  class="dropdown-toggle"
                  @click.prevent="profileMenuActive = !profileMenuActive"
                >
                  <span
                    :title="`${user.firstName} ${user.lastName}`"
                    class="img UserInitial user-avatar"
                  >
                    <template v-if="user.imageName"
                      ><img :src="`${user.imageName}`"
                    /></template>
                    <template v-else
                      >{{ user.firstName.charAt(0)
                      }}{{ user.lastName.charAt(0) }}</template
                    >
                  </span>
                  <span class="name"
                    >{{ user.firstName }} {{ user.lastName }}</span
                  >
                </button>
                <div class="dropdown-menu">
                  <a
                    class="dropdown-item"
                    href="javascript:void(0);"
                    @click.prevent="logout"
                    ><img src="@/assets/images/logout.png" />Logout</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-click-outside="onClickOutsideSiteNavActive"
          class="side-nav-wrapper"
        >
          <span
            class="menu-toggle"
            @click.prevent="sideNavActive = !sideNavActive"
          >
            <img src="@/assets/images/menu-toggle.png" />
          </span>
          <div
            id="mySidenav"
            :class="{ active: sideNavActive }"
            class="sidenav"
          >
            <a
              class="closebtn"
              href="javascript:void(0)"
              @click.prevent="sideNavActive = !sideNavActive"
              >×</a
            >
            <h4 class="menuTitle">Menu</h4>
            <ul >
              <li
                v-if="
                  permissions &&
                  permissions.profile_roster &&
                  permissions.profile_roster.length > 0 &&
                  permissions.profile_roster.includes('View')
                "
              >
                <a
                  href="javascript:void(0);"
                  @click="goToRoute('ProfileRoster')"
                  :class="{ active: $route.name === 'ProfileRoster' }"
                  >Profile Roster</a
                >
              </li>
              <li
                v-if="
                  permissions &&
                  permissions.profile_verification &&
                  permissions.profile_verification.length > 0 &&
                  permissions.profile_verification.includes('View')
                "
              >
                <a
                  href="javascript:void(0);"
                  @click="goToRoute('VerificationDashboard')"
                  :class="{ active: $route.name === 'VerificationDashboard' }"
                  >Verification Dashboard</a
                >
              </li>
              <li
                v-if="
                  permissions &&
                  permissions.profile_ownership &&
                  permissions.profile_ownership.length > 0 &&
                  permissions.profile_ownership.includes('View')
                "
              >
                <a
                  href="javascript:void(0);"
                  @click="goToRoute('ProfileOwnershipMapping')"
                  :class="{ active: $route.name === 'ProfileOwnershipMapping' }"
                  >Profile Ownership</a
                >
              </li>
              <li
                v-if="
                  permissions &&
                  permissions.financial_graphs &&
                  permissions.financial_graphs.length > 0 &&
                  permissions.financial_graphs.includes('View')
                "
              >
                <a
                  href="javascript:void(0);"
                  @click="goToRoute('FinancialGraphs')"
                  :class="{ active: $route.name === 'ProfileRoster' }"
                  >Financial Graphs</a
                >
                <ul style="margin-top: 14px; padding-left: 16px;">
                  <li> 
                    <a
                      href="javascript:void(0);"
                      @click="goToRoute('FinancialGraphsEarnings')"
                      :class="{ active: $route.name === 'ProfileRoster' }"
                      >Earnings</a
                    >
                  </li>

                  <li> 
                    <a
                      href="javascript:void(0);"
                      @click="goToRoute('FinancialGraphsCashFlow')"
                      :class="{ active: $route.name === 'ProfileRoster' }"
                      >Cash Flow</a
                    >
                  </li>

                  <li> 
                    <a
                      href="javascript:void(0);"
                      @click="goToRoute('FinancialGraphsServiceFee')"
                      :class="{ active: $route.name === 'FinancialGraphsServiceFee' }"
                      >Service Fee</a
                    >
                  </li>

                  <li> 
                    <a
                      href="javascript:void(0);"
                      @click="goToRoute('FinancialGraphsWithholdingTax')"
                      :class="{ active: $route.name === 'FinancialGraphs' }"
                      >Withholding Tax</a
                    >
                  </li>

                  <li> 
                    <a
                      href="javascript:void(0);"
                      @click="goToRoute('FinancialGraphsMembershipFee')"
                      :class="{ active: $route.name === 'FinancialGraphs' }"
                      >Membership Fee</a
                    >
                  </li>

                  <li> 
                    <a
                      href="javascript:void(0);"
                      @click="goToRoute('FinancialGraphsRefunds')"
                      :class="{ active: $route.name === 'FinancialGraphs' }"
                      >Refunds</a
                    >
                  </li>

                  <li> 
                    <a
                      href="javascript:void(0);"
                      @click="goToRoute('FinancialGraphsBonus')"
                      :class="{ active: $route.name === 'FinancialGraphs' }"
                      >Bonus</a
                    >
                  </li>

                  <li> 
                    <a
                      href="javascript:void(0);"
                      @click="goToRoute('FinancialGraphsConnects')"
                      :class="{ active: $route.name === 'FinancialGraphs' }"
                      >Connects</a
                    >
                  </li>

                </ul>
              </li>
              <!-- <li><a href="#">Productivity Reports</a></li> -->
            </ul>
            <!-- <template
              v-if="
                permissions &&
                permissions.settings &&
                permissions.settings.length > 0 &&
                permissions.settings.includes('View')
              "
            >
              <div class="seprator w-100"></div>
              <h4 class="menuTitle">Masters</h4>
              <ul>
                <li>
                  <a
                    href="javascript:void(0);"
                    @click="goToRoute('ManageCategories')"
                    :class="{ active: $route.name === 'ManageCategories' }"
                    >Manage Create Profile</a
                  >
                </li>
              </ul>
            </template> -->
          </div>
        </div>
      </div>
    </template>
  </header>
</template>

<script>
import common from "../mixins/common";

export default {
  name: "Header",
  data: () => ({
    profileMenuActive: false,
    sideNavActive: false,
    pageTitle: "",
    permissions: null,
  }),
  mixins: [common],
  computed: {
    isIdle() {
      return this.$store.state.idleVue.isIdle;
    },
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    contractUsername: function () {
      return this.$store.getters.username;
    },
    user: function () {
      return this.$store.getters.user;
    },
  },
  created() {
    this.permissions = this.userPermissions;
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push({ name: "Login" });
      this.profileMenuActive = false;
      // this.toastMessage('You are logged out successfully.', 'success');
    },
    onClickOutsideProfileMenu() {
      this.profileMenuActive = false;
    },
    onClickOutsideSiteNavActive() {
      this.sideNavActive = false;
    },
    goToRoute(RouteName) {
      this.sideNavActive = false;
      setTimeout(() => {
        return this.$route.name === RouteName
          ? this.$router.go({ name: RouteName })
          : this.$router.push({ name: RouteName });
      }, 300);
    },
  },
  watch: {
    isIdle(value) {
      if (value) {
        this.logout();
      }
    },
    $route(to) {
      this.sideNavActive = false;
      this.pageTitle = to.meta.title;
    },
    userPermissions: {
      handler(val) {
        this.permissions = val;
      },
    },
  },
};
</script>
