import {
    createStore
} from 'vuex'
import auth from "./modules/auth";
import loader from "./modules/loader";
import userMeta from "./modules/userMeta";
//import createPersistedState from "vuex-persistedstate";


const store = createStore({
    //plugins: [createPersistedState()],
    modules: {
        auth,
        loader,
        userMeta
    },
});

export default store;