import guest from "../middleware/guest";
import auth from "../middleware/auth";

const routes = [{
        path: "/",
        name: "Home",
        redirect: {
            name: "Dashboard",
        },
        meta: {
            middleware: auth,
        },
    },
    {
        path: "/login",
        name: "Login",
        component: () =>
            import ("../views/Login"),
        meta: {
            title: "Login",
            middleware: guest,
        },
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        redirect: {
            name: "ProfileRoster",
        },
        component: () =>
            import ("../components/Dashboard"),
        meta: {
            middleware: auth,
        },
        children: [{
                path: "profile-roster",
                name: "ProfileRoster",
                component: () =>
                    import ("../views/ProfileRoster.vue"),
                meta: {
                    title: "Profile Roster",
                    middleware: auth,
                },
            },
            {
                path: "profile-roster/:ID",
                name: "ProfileRosterDetail",
                component: () =>
                    import ("../views/ProfileRosterDetail.vue"),
                meta: {
                    title: "Profile Detail",
                    middleware: auth,
                },
            },
            {
                path: "profile-roster/:ID/contracts",
                name: "ProfileRosterContracts",
                component: () =>
                    import ("../views/ProfileRosterContracts.vue"),
                meta: {
                    title: "Contracts",
                    middleware: auth,
                },
            },
            {
                path: "profile-roster/:ID/billing",
                name: "ProfileRosterBilling",
                component: () =>
                    import ("../views/ProfileRosterBilling.vue"),
                meta: {
                    title: "Daily Billing",
                    middleware: auth,
                },
            },
            {
                path: "profile-ownership-mapping",
                name: "ProfileOwnershipMapping",
                component: () =>
                    import ("../views/ProfileOwnershipMapping.vue"),
                meta: {
                    title: "Profile Ownership Mapping",
                    middleware: auth,
                },
            },
            {
                path: "verification-dashboard",
                name: "VerificationDashboard",
                component: () =>
                    import ("../views/VerificationDashboard.vue"),
                meta: {
                    title: "Verification Dashboard",
                    middleware: auth,
                },
            },
            {
                path: "financial-graphs",
                name: "FinancialGraphs",
                component: () =>
                    import ("../views/FinancialGraphs.vue"),
                meta: {
                    title: "Financial Graphs",
                    middleware: auth,
                },
            },
            {
                path: "financial-graphs/connects",
                name: "FinancialGraphsConnects",
                component: () =>
                    import ("../views/FinancialGraphsConnects.vue"),
                meta: {
                    title: "Financial Graphs - Connects",
                    middleware: auth,
                },
            },
            {
                path: "financial-graphs/service-fee",
                name: "FinancialGraphsServiceFee",
                component: () =>
                    import ("../views/FinancialGraphsServiceFee.vue"),
                meta: {
                    title: "Financial Graphs - Service Fee",
                    middleware: auth,
                },
            },
            {
                path: "financial-graphs/withholding-tax",
                name: "FinancialGraphsWithholdingTax",
                component: () =>
                    import ("../views/FinancialGraphsWithholdingTax.vue"),
                meta: {
                    title: "Financial Graphs - WHT",
                    middleware: auth,
                },
            },
            {
                path: "financial-graphs/membership-fee",
                name: "FinancialGraphsMembershipFee",
                component: () =>
                    import ("../views/FinancialGraphsMembershipFee.vue"),
                meta: {
                    title: "Financial Graphs - Membership Fee",
                    middleware: auth,
                },
            },
            {
                path: "financial-graphs/refunds",
                name: "FinancialGraphsRefunds",
                component: () =>
                    import ("../views/FinancialGraphsRefunds.vue"),
                meta: {
                    title: "Financial Graphs - Refunds",
                    middleware: auth,
                },
            },
            {
                path: "financial-graphs/bonus",
                name: "FinancialGraphsBonus",
                component: () =>
                    import ("../views/FinancialGraphsBonus.vue"),
                meta: {
                    title: "Financial Graphs - Bonus",
                    middleware: auth,
                },
            },
            {
                path: "financial-graphs/earnings",
                name: "FinancialGraphsEarnings",
                component: () =>
                    import ("../views/FinancialGraphsEarnings.vue"),
                meta: {
                    title: "Financial Graphs - Earnings",
                    middleware: auth,
                },
            },
            {
                path: "financial-graphs/cash-flow",
                name: "FinancialGraphsCashFlow",
                component: () =>
                    import ("../views/FinancialGraphsCashFlow.vue"),
                meta: {
                    title: "Financial Graphs - Cash Flow",
                    middleware: auth,
                },
            },
            {
                path: "financial-graphs/detailed-report",
                name: "FinancialGraphsDetailedReport",
                component: () =>
                    import ("../views/FinancialGraphsDetailedReport.vue"),
                meta: {
                    title: "Financial Graphs - Detailed Report",
                    middleware: auth,
                },
            },
            {
                path: "financial-graphs/avg-earnings",
                name: "FinancialGraphsAverageEarnings",
                component: () =>
                    import ("../views/FinancialGraphsAverageEarnings.vue"),
                meta: {
                    title: "Financial Graphs - Earnings",
                    middleware: auth,
                },
            },
            {
                path: "manage",
                name: "Manage",
                component: () =>
                    import ("../components/Manage.vue"),
                meta: {
                    title: "Manage",
                    middleware: auth,
                },
                redirect: {
                    name: "ManageCategories",
                },
                children: [{
                        path: "categories",
                        name: "ManageCategories",
                        component: () =>
                            import ("../views/ManageCategories.vue"),
                        meta: {
                            title: "Manage - Categories",
                            middleware: auth,
                        },
                    },
                    {
                        path: "sub-categories",
                        name: "ManageSubCategories",
                        component: () =>
                            import ("../views/ManageSubCategories.vue"),
                        meta: {
                            title: "Manage - Sub Categories",
                            middleware: auth,
                        },
                    },
                    {
                        path: "expertise",
                        name: "ManageExpertise",
                        component: () =>
                            import ("../views/ManageExpertise.vue"),
                        meta: {
                            title: "Manage - Expertise",
                            middleware: auth,
                        },
                    },
                    {
                        path: "skills",
                        name: "ManageSkills",
                        component: () =>
                            import ("../views/ManageSkills.vue"),
                        meta: {
                            title: "Manage - Skills",
                            middleware: auth,
                        },
                    },
                ]
            },
        ],
    },
    {
        path: "/about",
        name: "About",
        component: () =>
            import ("../views/About.vue"),
    },
    {
        path: "/:catchAll(.*)",
        name: "PageNotFound.vue",
        component: () =>
            import ("../views/PageNotFound.vue"),
        meta: {
            title: "404",
        },
    },
];

export default routes;