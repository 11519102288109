import axios from 'axios';
import Countries from "../mixins/Countries";
import Cities from "../mixins/Cities";
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL;

class UserService {

  async getUser() {
    const config = {
      headers: authHeader()
    };

    const response = await axios
      .get(`${API_URL}get-user-info`, config);
    return response.data;
  }

  async isUniqueUserName(userName) {
    const config = {
      headers: authHeader()
    };

    const response = await axios
      .post(`${API_URL}check-username`, {
        userName
      }, config);
    return (response && response.data) ? true : false;
  }

  async getProfileTypes() {
    const config = {
      headers: authHeader()
    };

    const response = await axios
      .get(`${API_URL}profile-type`, config);
    return response.data;
  }

  async getCountries() {
    const response = [];
    response.data = Countries;
    return response;
  }

  async getCities(countryId) {
    const response = [];
    response.data = Cities.filter(City =>
      City.CountryId.includes(countryId)
    ).sort((a, b) => {
      if (a.text.toLowerCase().trim() < b.text.toLowerCase().trim())
        return -1;
      if (a.text.toLowerCase().trim() > b.text.toLowerCase().trim())
        return 1;
      return 0;
    });

    return response;
  }

  async getProfileCategories() {
    const config = {
      headers: authHeader()
    };

    const response = await axios
      .get(`${API_URL}profile-categories`, config);
    return response.data;
  }

  async getProfileSubCategories(ParentCategoryId) {
    const config = {
      headers: authHeader()
    };

    const response = await axios
      .post(`${API_URL}profile-sub-categories`, {
        ParentCategoryId
      }, config);
    return response.data;
  }

  async getLanguages() {
    const config = {
      headers: authHeader()
    };

    const response = await axios
      .get(`${API_URL}language`, config);
    return response.data;
  }

  async getLanguageProficiencyLevels() {
    const config = {
      headers: authHeader()
    };

    const response = await axios
      .get(`${API_URL}language-proficiency`, config);
    return response.data;
  }

  async getExpertise(categoryId) {
    const config = {
      headers: authHeader()
    };

    const response = await axios
      .post(`${API_URL}expertise`, {
        categoryId
      }, config);
    return response.data;
  }

  async getExpertiseLevels() {
    const config = {
      headers: authHeader()
    };

    const response = await axios
      .get(`${API_URL}expertise-level`, config);
    return response.data;
  }

  async getSkills() {
    const config = {
      headers: authHeader()
    };

    const response = await axios
      .get(`${API_URL}skills`, config);
    return response.data;
  }

}

export default new UserService();