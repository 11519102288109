const state = {
    loadingStatus: false,
};

const mutations = {
    loadingStatus(state, newLoadingStatus) {
        state.loadingStatus = newLoadingStatus
    }
};

const actions = {

};

const getters = {
    loadingStatus(state) {
        return state.loadingStatus;
    }
};

export default {
    state,
    mutations,
    actions,
    getters,
};