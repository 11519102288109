import moment from "moment-timezone";

export default {
    currencyUSD(value) {
        return '$' + value
    },
    dateFormat(value, format) {
        if (value) {
            const parsedDate = moment(String(value));
            return parsedDate.format(format);
        } else {
            return moment().format(format);
        }
    }
}