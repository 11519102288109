const state = {
    username: '',
};

const mutations = {
    username(state, newUsername) {
        state.username = newUsername
    }
};

const actions = {

};

const getters = {
    username(state) {
        return state.username;
    }
};

export default {
    state,
    mutations,
    actions,
    getters,
};